













































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import {
    readBrandExts,
} from '@/store/admin/getters';
import {
    dispatchGetBrandExts,
} from '@/store/admin/actions';
import {
    readImpersonated,
} from '@/store/affi/getters';
import {
    commitSetImpersonatedOwnerId,
    commitSetImpersonatedName,
} from '@/store/affi/mutations';

import { ttt } from '@/nutils';

interface IPagination {
  rowsPerPage: number;
  totalItems: number;
  page: number;
};

@Component
export default class AdminUsers extends Vue {
  public ttt = ttt;
  public selected: number[] = [];
  public pagination: IPagination = { rowsPerPage: 10, totalItems: 0, page: 1, };
  public search: string = '';

  public headers = [
    { text: ttt('Shop Name'), sortable: true, value: 'shop_name', align: 'left', },
    { text: ttt('Shopify Name'), sortable: true, value: 'shopify_name', align: 'left', },
    { text: '', sortable: false, value: 'shop_url', align: 'left', width: '48px', },
    { text: ttt('Shop Description'), sortable: true, value: 'shop_description', align: 'left', },
    { text: ttt('Impressions'), sortable: true, value: 'analytics_displays', align: 'left', },
    { text: ttt('Leads'), sortable: true, value: 'analytics_ctrs', align: 'left', },
    { text: ttt('Conversions'), sortable: true, value: 'analytics_orders', align: 'left', },
    { text: ttt('Registered'), sortable: true, value: 'registered_at', align: 'left', },

    { text: ttt('Status'), value: 'all_completed', sortable: false, align: 'left', },
  ];


  get pages() {
    if (this.pagination.totalItems === 0)
        this.pagination.totalItems = this.brands.length;
    return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  get brands() {
    return readBrandExts(this.$store);
  }

  public async mounted() {
    await dispatchGetBrandExts(this.$store);
    const imp = readImpersonated(this.$store);
    if (imp !== -1) {
        this.selected = [imp];
    }
  }

  public submit() {
    const ownerId = this.selected[0];
    // console.log(ownerId);
    commitSetImpersonatedOwnerId(this.$store, ownerId);
    const shop = this.brands
            .find( (obj) => ( obj && obj.owner_id === ownerId ))
    const shopName = shop ? shop.shop_name : '';
    commitSetImpersonatedName(this.$store, shopName);

    this.$router.push('/main/admin/admindashboard');
  }

  public cancel() {
    this.$router.back();
  }
  public rowClicked(item) {
    this.selected = [item.owner_id];
  }
  public reset() {
    this.selected = [];
    commitSetImpersonatedOwnerId(this.$store, -1);
    commitSetImpersonatedName(this.$store, '');

    this.$router.push('/main/admin/admindashboard');
  }
  public openwindow(url) {
    window.open(url, '_blank');
  }

  public all_completed(item) {
    return (
        item.has_connect_plugin &&
        item.payment_status==='approved' &&
        item.has_shop_settings &&
        item.has_choose_partners &&
        item.has_choose_products &&
        true // item.has_activate_plugin
    );
  }

  public completion_text(item) {
    const tooltip : { on: boolean, text: string }[] = [];

    tooltip.push( { on: item.has_connect_plugin, text: ttt('Connected to Shopify') } );
    tooltip.push( { on: item.payment_status==='approved', text: ttt('Paid Subscription') } );
    tooltip.push( { on: item.has_shop_settings, text: ttt('Brand Settings') } );
    tooltip.push( { on: item.has_choose_partners, text: ttt('Choose Partners') } );
    tooltip.push( { on: item.has_choose_products, text: ttt('Choose Products') } );

    return tooltip;
  }
}
